import { createActions } from '../../utils';

let resources = [
  'order history'
],
otherActions = [
  'agent register',
  'user register',
  'registering',
  'registered agent',
  'registered user',
  'error registering',
  'user login',
  'logging in',
  'logged in',
  'error logging in',
  'log out',
  'change password',
  'changing password',
  'changed password',
  'error changing password',
  'reset changing password',
  'error loading order history',
];

export default createActions(resources, otherActions);
