import { createActions } from '../../utils';

const resources = [
  'quotation',
  'plan and product',
  'quotation history',
  'existing member',
  'plan',
  'related plans',
  'related plans with quotation id',
  'user profile',

  'existing users',
  'benefits and tsi',
  'persons by phone number'
];

const otherActions = [
  'error loading quotation',

  'submit quotation',
  'submitting quotation',
  'submitted quotation',
  'error submitting quotation',
  'reset quotation error',
  'reset quotation response',

  'reset application data',

  'error loading plan and product',

  'error loading quotation',
  'error loading quotation history',
  'error loading existing member',
  'error loading related plans',
  'error loading plan',
  'error loading user profile',

  'delete member',

  'update status',
  'updating status',
  'updated status',
  'error updating status',
  'reset updated status',

  'error loading existing users',
  'error loading benefits and tsi',

  'save person',
  'saving person',
  'error saving person',
  'error loading persons by phone number'
];

export default createActions(resources, otherActions);
