import { createActions } from '../../utils';

const resources = [
  'notification list',
];

const otherActions = [
  'mark as read',
];

export default createActions(resources, otherActions);
