/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import {
  differenceInDays,
  differenceInMinutes,
  isPast,
} from 'date-fns';
import get from 'lodash/get';
import { actionTypes } from '../../../shell/actions';
import { leadStatus } from '../../../../shared/enums';
import { parseJSON } from '../../../utils/parseJSON';

const deafultState = {
  loading: false,
  error: false,
  errorMessage: '',
  quotationDetail: {},
  agentMonthlyData: [],
  planDetail: {},
  orderDetail: {},
  productQuestionsDetail: {},
  declarationDetail: [],
  updatePersonStatus: false,
  updatePolicyMemberStatus: false,
  updateDeclarationStatus: false,
  tablePagination: {
    page: 1,
    limit: 10,
    pageCount: 1,
  },
  tableStatus: '',
  person: {},
  personalAccount: {},
  leadInfo: {},
  leadQuotationInfo: {},
  leadList: [],
  bankAccountDetail: [],
  agentBalance: {},
  withdrawalReferenceInfo: {},
  withdrawalSummary: {},
  newLead: {},
  agentLevel: [],
  currentLevel: {},
  quotationsStatus: false,
  leadSummaryList: {},
  transactionSummary: {},
  agentPerformance: {},
  withdrawalSummaryList: {},
  agentData: {},
  userDetails: [],
  queueEntry: {},
  isAgentAvailable: false,
  callerInfo: '',
  notesHistory: [],
  isManualDialEnabled: false,
  leaderboard: [],
  callRecordingList: [],
  callByDuration: [],
  callByStatus: [],
  leadCallRecordingList: [],
  agentStatusList: [],
  agentLeadList: [],
  agentDetail: {},
  agentTeams: [],
  callLogs: [],
  expandedLeadChatList: [],
  leadChatList: [],
  agentChatHistory: [],
  agentHasUnreadMsg: false,
  selectedLead: {},
};

function normalizeMonthlyData(monthlyData = []) {
  return monthlyData.map((row) => ({
    id: row.quotationId,
    createdAt: row.createdAt,
    email: row.email,
    orderId: row.orderHumanId || 'N/A',
    fullName: row.name || 'N/A',
    phone: row.phoneNumber ? `0${row.phoneNumber}` : 'N/A',
    productName: row.productName || 'N/A',
    planName: row.planName || 'N/A',
    premi: row.totalAmount || 'N/A',
    policy: row.policy || {},
    status: {
      orderStatus: row.statusOrder,
      quotationStatus: row.quotationStatus,
    },
  }));
}

function normalizeTransactionSummary(transactionSummary = []) {
  return transactionSummary.reduce((acc, summary) => {
    acc[summary.status] = summary.lead_count;
    return acc;
  }, {});
}

function normalizeAgentPerformance(agentPerformance = []) {
  return agentPerformance.reduce((acc, performance) => {
    if (['PAYMENT_PENDING', 'PAYMENT_PROCESSING'].includes(performance.status)) {
      acc['pendingAmount'] += Number(performance.totalAmount);
    } else if (['FULLY_PAID', 'PARTIALLY_PAID'].includes(performance.status)) {
      acc['paidAmount'] += Number(performance.paidAmount);
    }
    return acc;
  }, { paidAmount: 0, pendingAmount: 0 });
}

function filterAgentLeaderboard(agentLeaderboard, agentId) {
  if (agentLeaderboard.length) {
    const clonedAgentLeaderboard = [...agentLeaderboard];

    const result = clonedAgentLeaderboard
      .slice(0, 10)
      .map((agent, i) => ({ ...agent, rank: i + 1, isSelf: false }));

    const agentIndex = agentLeaderboard.findIndex(agent => agent.id === agentId);
    if (agentIndex > 9) {
      result.push({ ...agentLeaderboard[agentIndex], rank: agentIndex + 1, isSelf: true });
    } else {
      result[agentIndex] = { ...result[agentIndex], isSelf: true };
    }

    return result;
  } else {
    return [];
  }
}

// @todo: get rid of this function and clean it's logic
function sortOrderBasedOnSchedule(quotations) {
  // sort by schedule priority
  const orderWithUrgentSchedule = quotations.filter((item) => new Date(item.schedule.date).getTime() <= new Date().getTime());
  const orderWithNormalSchedule = quotations.filter((item) => new Date(item.schedule.date).getTime() > new Date().getTime());
  const orderwithoutSchedule = quotations.filter((item) => !item.schedule.date);

  let lists = [];

  lists = [
    ...orderwithoutSchedule,
    ...orderWithUrgentSchedule,
    ...orderWithNormalSchedule,
  ];

  // sort ascending
  lists = lists.sort((a, b) => new Date(a.schedule.date).getTime() - new Date(b.schedule.date).getTime());

  return lists;
}

/**
 *
 * @param {Object} state - Redux state
 * @param {Object} response - server response
 */

export default function agentReducers(state = deafultState, action) {
  switch (action.type) {
    case actionTypes.UPDATING_QUOTATION:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATED_QUOTATION:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_UPDATING_QUOTATION:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.LOADING_AGENT_MONTHLY_DATA:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_AGENT_MONTHLY_DATA:
      return {
        ...state,
        agentMonthlyData: normalizeMonthlyData(action.payload.monthlyData.data.allData),
        tablePagination: {
          ...state.tablePagination,
          page: 1,
          pageCount: action.payload.monthlyData.data.pageCount,
        },
        loading: false,
      };
    case actionTypes.ERROR_LOADING_AGENT_MONTHLY_DATA:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.SEARCHING_AGENT_DASHBOARD:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case actionTypes.AGENT_DASHBOARD_SEARCH_RESULT:
      return {
        ...state,
        agentMonthlyData: normalizeMonthlyData(
          action.payload.monthlyData.data
            ? action.payload.monthlyData.data.allData
            : [],
        ),
        tablePagination: {
          ...state.tablePagination,
          page: 1,
          pageCount: action.payload.monthlyData.data.pageCount
            ? action.payload.monthlyData.data.pageCount
            : 1,
        },
        loading: false,
      };
    case actionTypes.UPDATE_TABLE_STATUS:
      return {
        ...state,
        tableStatus: action.payload.tableStatus,
      };
    case actionTypes.UPDATING_SCHEDULE:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATED_SCHEDULE:
      let listQuotations = [];
      const quotation = action.payload.data;

      // @todo: avoid writing business logic in reducer switch case
      if (state.tableStatus === 'suspended') listQuotations = state.suspendedQuotations;
      else if (state.tableStatus === 'in-process') listQuotations = state.inProcessQuotations;
      else if (state.tableStatus === 'awaiting-issuance') listQuotations = state.awaitingIssuanceQuotations;

      const listUpdated = listQuotations.map((row) => {
        if (row.id === quotation.quotationId) {
          row.schedule.date = quotation.nextCallDate;
          row.schedule.orderId = quotation.id;
          row.schedule.reason = quotation.callbackReason;
        }

        return row;
      });

      return {
        ...state,
        ...state.tableStatus === 'suspended' && { suspendedQuotations: sortOrderBasedOnSchedule(listUpdated) },
        ...state.tableStatus === 'in-process' && { inProcessQuotations: sortOrderBasedOnSchedule(listUpdated) },
        ...state.tableStatus === 'awaiting-issuance' && { awaitingIssuanceQuotations: sortOrderBasedOnSchedule(listUpdated) },
        loading: false,
      };
    case actionTypes.ERROR_UPDATING_SCHEDULE:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.LOADING_AGENT_QUOTATION_PLAN_AND_ORDER:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case actionTypes.LOADED_AGENT_QUOTATION_PLAN_AND_ORDER:
      return {
        ...state,
        quotationDetail: action.payload.quotation.data,
        planDetail: action.payload.plan.data,
        declarationDetail: action.payload.declaration.data,
        orderDetail: action.payload.order.data,
        error: false,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_AGENT_QUOTATION_PLAN_AND_ORDER:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.UPDATING_PERSON:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATED_PERSON:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_UPDATING_PERSON:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.RESET_UPDATE_PERSON_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: '',
      };
    case actionTypes.SET_UPDATE_PERSON_STATUS:
      return {
        ...state,
        updatePersonStatus: true,
      };
    case actionTypes.RESET_UPDATE_PERSON_STATUS:
      return {
        ...state,
        updatePersonStatus: false,
      };
    case actionTypes.UPDATING_POLICY_MEMBER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATED_POLICY_MEMBER:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_UPDATING_POLICY_MEMBER:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.SET_UPDATE_POLICY_MEMBER_STATUS:
      return {
        ...state,
        updatePolicyMemberStatus: true,
      };
    case actionTypes.RESET_UPDATE_POLICY_MEMBER_STATUS:
      return {
        ...state,
        updatePolicyMemberStatus: false,
      };
    case actionTypes.UPDATING_DECLARATION:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATED_DECLARATION:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_UPDATING_DECLARATION:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.SET_UPDATE_DECLARATION_STATUS:
      return {
        ...state,
        updateDeclarationStatus: true,
      };
    case actionTypes.RESET_UPDATE_DECLARATION_STATUS:
      return {
        ...state,
        updateDeclarationStatus: false,
      };
    case actionTypes.UPDATING_REFUND_ORDER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATED_REFUND_ORDER:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_UPDATING_REFUND_ORDER:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.LOADING_PERSON:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_PERSON:
      return {
        ...state,
        person: get(action, 'payload.person.data[0]', {}),
        loading: false,
      };
    case actionTypes.ERROR_LOADING_PERSON:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.UPDATING_PERSONAL_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATED_PERSONAL_ACCOUNT:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_UPDATING_PERSONAL_ACCOUNT:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.LOADING_PERSONAL_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_PERSONAL_ACCOUNT:
      return {
        ...state,
        loading: false,
        personalAccount: action.payload.personalAccount.data,
      };
    case actionTypes.ERROR_LOADING_PERSONAL_ACCOUNT:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.LOADING_LEAD_INFO:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_LEAD_INFO:
      return {
        ...state,
        leadInfo: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_LEAD_INFO:
      return {
        ...state,
        error: true,
        loading: false,
        leadInfo: {},
      };
    case actionTypes.LOADING_LEAD_QUOTATION_INFO:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_LEAD_QUOTATION_INFO:
      return {
        ...state,
        leadQuotationInfo: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_LEAD_QUOTATION_INFO:
      return {
        ...state,
        error: true,
        leadQuotationInfo: {},
        loading: false,
      };
    case actionTypes.LOADING_LEAD_LIST:
      return {
        ...state,
        loading: true,
        leadList: [],
      };
    case actionTypes.LOADED_LEAD_LIST:
      const formattedLeadList = action.payload
        .response.data.map((lead) => {
          lead.lead_info.callBackTime = lead.lead_info.next_call_date_time;
          lead.lead_info.fullName = `${lead.lead_info.first_name} ${lead.lead_info.last_name}`;
          lead.lead_info.lastUpdatedAt = differenceInDays(new Date(), new Date(lead.lead_info.updated_at));
          lead.lead_info.leadStatus = leadStatus[lead.lead_info.status] ? leadStatus[lead.lead_info.status].status : '';
          lead.lead_info.phoneNumber = lead.lead_info.phone_number;
          lead.lead_info.callbackReason = lead.lead_info.callback_reason;
          lead.lead_info.chatList = [];
          lead.lead_info.leadChatPageNumber = '';
          lead.lead_info.wa_status = 'read';
          lead.lead_info.order = null;
          lead.lead_info.isDialling = false;

          const isLeadNotRescheduled = isPast(new Date(lead.lead_info.next_call_date_time));
          if (!isLeadNotRescheduled) {
            lead.lead_info.nextCallDate = -1;
          } else {
            lead.lead_info.nextCallDate = differenceInMinutes(new Date(), new Date(lead.lead_info.next_call_date_time));
          }
          return lead;
        });
      window.localStorage.setItem('leadList', JSON.stringify(formattedLeadList));
      return {
        ...state,
        leadList: formattedLeadList,
        loading: false,
      };
    case actionTypes.REJECTING_LEAD:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REJECTED_LEAD:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_REJECTING_LEAD:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.UPDATING_QUEUE_SCHEDULE:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATED_QUEUE_SCHEDULE:
      const _clonedLeadList = [...state.leadList];
      if (action.payload.response.data && action.payload.response.data.id && action.payload.shouldSplice) {
        const rejectedLeadIndex = _clonedLeadList.findIndex(lead => lead.id === action.payload.response.data.id);
        if (rejectedLeadIndex > -1) {
          _clonedLeadList.splice(rejectedLeadIndex, 1);
        }
      }
      return {
        ...state,
        loading: false,
        leadList: _clonedLeadList,
      };
    case actionTypes.ERROR_UPDATING_QUEUE_SCHEDULE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.LOADING_BANK_ACCOUNT_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_BANK_ACCOUNT_DETAIL:
      return {
        ...state,
        bankAccountDetail: action.payload.response.data.filter((details) => {
          if (details.mainProfile) return details;
        }),
        loading: false,
      };
    case actionTypes.LOADING_BALANCE:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_BALANCE:
      let balanceInfo = action.payload.response.data;
      if (Object.keys(balanceInfo).length) {
        balanceInfo.totalBalance = balanceInfo.availableBalance - balanceInfo.pendingWithdrawal;
        balanceInfo.maxWithdrawalBalance = balanceInfo.availableWithdrawalBalance - balanceInfo.pendingWithdrawal;
      }
      return {
        ...state,
        agentBalance: balanceInfo,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_BALANCE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.PROCESSING_WITHDRAWAL:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PROCESSED_WITHDRAWAL:
      return {
        ...state,
        withdrawalSummary: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_PROCESSING_WITHDRAWAL:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.CREATING_LEAD:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATED_LEAD:
      return {
        ...state,
        loading: false,
        newLead: action.payload.response.data,
      };
    case actionTypes.ERROR_CREATING_LEAD:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.CLEAR_LEAD:
      return {
        ...state,
        newLead: {},
        errorMessage: '',
      }
    case actionTypes.AUTHENTICATING_WITHDRAWAL:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.AUTHENTICATED_WITHDRAWAL:
      return {
        ...state,
        withdrawalReferenceInfo: action.payload.response.data,
        loading: false,
      }
    case actionTypes.ERROR_AUTHENTICATING_WITHDRAWAL:
      return {
        ...state,
        loading: false,
        withdrawalReferenceInfo: action.payload.errorMessage,
      }
    case actionTypes.RESET_AUTHENTICATED_WITHDRAWAL:
      return {
        ...state,
        withdrawalReferenceInfo: {},
      }
    case actionTypes.LOADING_AGENT_LEVEL:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_AGENT_LEVEL:
      return {
        ...state,
        agentLevel: action.payload.agentLevel.data,
        currentLevel: action.payload.agentLevel.data.find(data => data.is_current_level === 1),
        loading: false,
      };
    case actionTypes.ERROR_LOADING_AGENT_LEVEL:
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.payload.errorMessage,
        leadInfo: {},
      };
    case actionTypes.UPDATING_QUOTATIONS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATED_QUOTATIONS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_UPDATING_QUOTATIONS:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.SET_QUOTATIONS_STATUS:
      return {
        ...state,
        quotationsStatus: true,
      };
    case actionTypes.RESET_QUOTATIONS_STATUS:
      return {
        ...state,
        quotationsStatus: false,
      };
    case actionTypes.LOADING_LEAD_SUMMARY_LIST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_LEAD_SUMMARY_LIST:
      return {
        ...state,
        leadSummaryList: action.payload.response.data,
        loading: false,
      };
    case actionTypes.LOADING_TRANSACTION_SUMMARY:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_TRANSACTION_SUMMARY:
      return {
        ...state,
        transactionSummary: normalizeTransactionSummary(action.payload.response.data),
        loading: false,
      };
    case actionTypes.ERROR_LOADING_TRANSACTION_SUMMARY:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.LOADING_AGENT_PERFORMANCE:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_AGENT_PERFORMANCE:
      return {
        ...state,
        agentPerformance: normalizeAgentPerformance(action.payload.response.data),
        loading: false,
      };
    case actionTypes.ERROR_LOADING_AGENT_PERFORMANCE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.LOADING_WITHDRAWAL_SUMMARY_LIST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_WITHDRAWAL_SUMMARY_LIST:
      return {
        ...state,
        withdrawalSummaryList: action.payload.response.data,
        loading: false,
      };
    case actionTypes.LOADING_AGENT_DATA:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_AGENT_DATA:
      return {
        ...state,
        agentData: action.payload.agentData.data,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_AGENT_DATA:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.RESET_AGENT_DATA:
      return {
        ...state,
        loading: false,
        agentData: {},
      };
    case actionTypes.LOADING_AGENT_TEAMS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_AGENT_TEAMS:
      return {
        ...state,
        agentTeams: action.payload.agentTeams.data,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_AGENT_TEAMS:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.LOADING_USER_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_USER_DETAILS:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.CREATING_QUEUE_ENTRY:
      return {
        ...state,
        loading: true,
        queueEntry: {},
      };
    case actionTypes.CREATED_QUEUE_ENTRY:
      return {
        ...state,
        loading: false,
        queueEntry: {
          message: 'queue service is notified'
        },
      };
    case actionTypes.ERROR_CREATING_QUEUE_ENTRY:
      return {
        ...state,
        error: true,
        loading: false,
        queueEntry: {
          message: 'queue service is notified'
        },
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.UPDATE_AGENT_AVAILABILITY:
      let clonedAgentDetails = { ...state.agentData };
      clonedAgentDetails.is_active = action.payload.agentStatusEvent.is_active;
      return {
        ...state,
        loading: false,
        agentData: clonedAgentDetails,
        isAgentAvailable: action.payload.agentStatusEvent.is_active,
      };
    case actionTypes.UPDATE_AUTODIAL_AVAILABILITY:
      let clonedAgentDetail = { ...state.agentData };
      clonedAgentDetail.is_auto_dial_enabled = action.payload.agentStatusEvent.auto_dial_status;
      return {
        ...state,
        loading: false,
        agentData: clonedAgentDetail,
        isAutoDialEnabled: action.payload.agentStatusEvent.auto_dial_status,
      };
    case actionTypes.MARKING_AGENT_AVAILABILITY:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MARKED_AGENT_AVAILABILITY:
      let clonedAgentData = { ...state.agentData };
      clonedAgentData.is_active = action.payload.response.data.is_active;
      return {
        ...state,
        loading: false,
        agentData: clonedAgentData,
        isAgentAvailable: action.payload.response.data.is_active,
      };
    case actionTypes.ERROR_MARKING_AGENT_AVAILABILITY:
      return {
        ...state,
        error: true,
        loading: false,
        isAgentAvailable: false,
      };
    case actionTypes.INITIATING_MANUAL_DIAL:
      return {
        ...state,
        loading: true,
        callerInfo: action.payload.lead
      };
    case actionTypes.INITIATED_MANUAL_DIAL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_INITIATING_MANUAL_DIAL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.RESET_CALLER_INFO:
      return {
        ...state,
        callerInfo: '',
      };
    case actionTypes.LOADING_NOTES_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_NOTES_HISTORY:
      return {
        ...state,
        notesHistory: action.payload.response.data,
        loading: false,
      };
    case actionTypes.ERROR_LOADING_NOTES_HISTORY:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.RESET_NOTES_HISTORY:
      return {
        ...state,
        loading: false,
        notesHistory: [],
      };
    case actionTypes.CREATING_NOTES:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATED_NOTES:
      let clonedNotesHistory = [...state.notesHistory];

      const { queue, agent, ...newNotesData } = action.payload.response.data;
      newNotesData.first_name = state.agentData.first_name;
      newNotesData.last_name = state.agentData.last_name;
      newNotesData.created_at = action.payload.response.data.created_at;

      clonedNotesHistory.unshift(newNotesData);
      return {
        ...state,
        notesHistory: clonedNotesHistory,
        loading: false,
      };
    case actionTypes.ERROR_CREAING_NOTES:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case actionTypes.INITIATING_AUTODIAL:
      return {
        ...state,
        loading: true,
        callerInfo: action.payload.lead
      };
    case actionTypes.INITIATED_AUTODIAL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ERROR_INITIATING_AUTODIAL:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.SET_MANUAL_DIAL:
      localStorage.setItem('isManualDialEnabled', action.payload.isEnabled);
      window.isAgentOnCall.next(action.payload.isEnabled);
      const newState = { ...state, isManualDialEnabled: action.payload.isEnabled };
      return {
        ...newState,
      };
    case actionTypes.SET_AGENT_STATUS:
      let clonedAgent = { ...state.agentData };
      clonedAgent.is_active = action.payload.data.isActive;
      return {
        ...state,
        loading: false,
        agentData: clonedAgent,
      };
    case actionTypes.LOADING_AGENT_LEADERBOARD:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_AGENT_LEADERBOARD:
      return {
        ...state,
        loading: false,
        leaderboard: filterAgentLeaderboard(action.payload.response.data, action.payload.agentId),
      };
    case actionTypes.ERROR_LOADING_AGENT_LEADERBOARD:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.LOADING_CALL_RECORDING_LIST:
      return {
        ...state,
        loading: true,
        callRecordingList: [],
        callByDuration: [],
        callByStatus: [],
      };
    case actionTypes.LOADED_CALL_RECORDING_LIST:
      const { call_record_list, call_record_by_duration, call_record_by_status } = action.payload.callRecordingList.data;
      return {
        ...state,
        loading: false,
        callRecordingList: call_record_list,
        callByDuration: call_record_by_duration,
        callByStatus: call_record_by_status,
      };
    case actionTypes.ERROR_LOADING_CALL_RECORDING_LIST:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.LOADING_CALL_RECORDING_BY_NUMBER:
      return {
        ...state,
        loading: true,
        leadCallRecordingList: [],
      };
    case actionTypes.LOADED_CALL_RECORDING_BY_NUMBER:
      return {
        ...state,
        loading: false,
        leadCallRecordingList: action.payload.recordingList.data,
      };
    case actionTypes.ERROR_LOADING_CALL_RECORDING_BY_NUMBER:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.LOADING_AGENT_STATUS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOADED_AGENT_STATUS:
      const formattedAgentStatusList = action.payload.agentStatusList.data.map(agent => {
        agent.name = agent.first_name ? `${agent.first_name + agent.last_name}`.trim() : '-';
        let presentRate = agent.unique_calls ? `${agent.unique_successful_calls / agent.unique_calls}` : '0';
        agent.canReceiveLeads = false;
        agent.autoDialStatus = agent.is_auto_dial_enabled ? `${agent.is_on_call ? 'Dailing' : 'Waiting'}` : 'Stopped';
        if (
          (agent.new_count < agent.max_current_new)
          && (agent.assigned_today < agent.daily_limit)
          && (!agent.is_on_call)
          && (agent.is_active)
        ) {
          agent.canReceiveLeads = true;
        }
        agent.presentRate = `${(Number(presentRate) * 100).toFixed(1)}%`
        return agent;
      });
      return {
        ...state,
        loading: false,
        agentStatusList: formattedAgentStatusList,
      };
    case actionTypes.ERROR_LOADING_AGENT_STATUS:
      return {
        ...state,
        loading: false,
        agentStatusList: [],
        error: true
      };
    case actionTypes.UPDATE_AGENT_STATS:
      return {
        ...state,
        agentStatusList: action.payload.agentStatusList
      }
    case actionTypes.LOADING_LEAD_LIST_BY_AGENTID:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_LEAD_LIST_BY_AGENTID:
      return {
        ...state,
        agentLeadList: action.payload.response.data,
        loading: false,
      }
    case actionTypes.LOADING_AGENT_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_AGENT_DETAIL:
      return {
        ...state,
        agentDetail: action.payload.response.data,
        loading: false,
      }
    case actionTypes.ERROR_LOADING_AGENT_DETAIL:
      return {
        ...state,
        error: true,
        loading: false,
      }
    case actionTypes.LOADING_CALL_LOGS_BY_LEAD:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_CALL_LOGS_BY_LEAD:
      return {
        ...state,
        callLogs: [
          ...state.callLogs,
          ...action.payload.response.data,
        ],
        loading: false,
      }
    case actionTypes.ERROR_LOADING_CALL_LOGS_BY_LEAD:
      return {
        ...state,
        error: true,
        loading: false,
      }
    case actionTypes.LOADING_CALL_LOGS_BY_AGENT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_CALL_LOGS_BY_AGENT:
      return {
        ...state,
        callLogs: [
          ...state.callLogs,
          ...action.payload.response.data,
        ],
        loading: false,
      }
    case actionTypes.ERROR_LOADING_CALL_LOGS_BY_AGENT:
      return {
        ...state,
        error: true,
        loading: false,
      }
    case actionTypes.RESET_CALL_LOGS:
      return {
        ...state,
        callLogs: [],
      }
    case actionTypes.LOADING_LEAD_BY_LEADID:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_LEAD_BY_LEADID:
      let clonedLeadList = [...state.leadList];
      if (action.payload.response && action.payload.response.data && action.payload.response.data.id) {
        const updatedLeadIndex = clonedLeadList.findIndex(lead => lead.lead_info.id === action.payload.response.data.id);
        const lead = action.payload.response.data;
        const clonedLeadInfo = { ...clonedLeadList[updatedLeadIndex] };

        lead.callBackTime = lead.next_call_date_time;
        lead.fullName = `${lead.first_name} ${lead.last_name}`;
        lead.lastUpdatedAt = differenceInDays(new Date(), new Date(lead.updated_at));
        lead.leadStatus = leadStatus[lead.status] ? leadStatus[lead.status].status : '';
        lead.callbackReason = lead.callback_reason;

        const isLeadNotRescheduled = isPast(new Date(lead.next_call_date_time));

        if (updatedLeadIndex > -1) {
          lead.phoneNumber = clonedLeadInfo.lead_info.phone_number;
          lead.nextCallDate = !isLeadNotRescheduled
            ? -1
            : differenceInMinutes(new Date(), new Date(lead.lead_info.next_call_date_time));
          clonedLeadList[updatedLeadIndex] = {
            lead_info: lead
          };
        } else {
          lead.phoneNumber = lead.phone_number;
          lead.nextCallDate = -1;
          lead.chatList = [];
          lead.leadChatPageNumber = '';
          lead.wa_status = 'read';
          lead.order = null;
          lead.isDialling = false;
          clonedLeadList.unshift({
            lead_info: lead
          });
        }
        window.localStorage.setItem('leadList', JSON.stringify(clonedLeadList));
      }
      return {
        ...state,
        loading: false,
        leadList: clonedLeadList,
      }
    case actionTypes.LOADED_MESSAGE_LIST:
      const { response, leadId, pageNumber } = action.payload;
      const leadList = [...state.leadList];
      const leadIndex = state.leadList.findIndex(lead => lead.id === leadId);

      if (pageNumber === 1) {
        leadList[leadIndex].chatList = response.data.chat_message_list;
        leadList[leadIndex].lastIncomingMsg = response.data.last_inbound_msg;
        leadList[leadIndex].leadChatPageNumber = pageNumber;
      } else {
        if (response.data && response.data.chat_message_list && response.data.chat_message_list.length) {
          let paginatedResponse = response.data.chat_message_list;
          paginatedResponse = paginatedResponse.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          );
          leadList[leadIndex].chatList = [...paginatedResponse, ...leadList[leadIndex].chatList];
        }
      }

      window.localStorage.setItem('leadList', JSON.stringify(leadList));

      return {
        ...state,
        loading: false,
        leadList
      }
    case actionTypes.ADD_NEW_MESSAGE:
      const updatedLeadList = [...state.leadList];
      const expandedLeadList = [...state.expandedLeadChatList];
      const { data } = action.payload;
      const leadIndex_ = updatedLeadList.findIndex(lead => lead.id === action.payload.leadId);
      const leadExpandedIndex = expandedLeadList.findIndex(lead => lead.id === action.payload.leadId);
      if (!action.payload.isWidget) updatedLeadList[leadIndex_].chatList.push(data);
      if (action.payload.isWidget && leadExpandedIndex > -1) {
        expandedLeadList[leadExpandedIndex].chatList.push(data);
      }
      if (state.selectedLead.id !== action.payload.leadId) {
        updatedLeadList[leadIndex_].wa_status = data.wa_status;
      }
      window.localStorage.setItem('leadList', JSON.stringify(updatedLeadList));
      return {
        ...state,
        leadList: updatedLeadList,
        expandedLeadChatList: expandedLeadList,
      }
    case actionTypes.SENDING_TEMPLATE:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SENT_TEMPLATE:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.LOADING_AGENT_CHAT_HISTORY:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.LOADED_AGENT_CHAT_HISTORY:
      const agentLeadList = [...state.leadList];
      const agentRecentChatList = action.payload.response.data;
      let hasUnreadMsg = false;
      agentRecentChatList.forEach(chat => {
        if (chat.traffic === 'inbound' && chat.status.toLowerCase() !== 'read') {
          const agentLeadIndex = agentLeadList.findIndex(lead => lead.id === chat.lead_id);
          agentLeadList[agentLeadIndex].wa_status = 'unread';
          hasUnreadMsg = true;
        }
      });
      window.localStorage.setItem('leadList', JSON.stringify(agentLeadList));
      return {
        ...state,
        loading: false,
        agentChatHistory: agentRecentChatList,
        leadList: agentLeadList,
        agentHasUnreadMsg: hasUnreadMsg,
      }
    case actionTypes.SET_AGENT_UNREAD_STATUS:
      const _leadList = [...state.leadList];
      const _leadIndex = _leadList.findIndex(lead => lead.id === action.payload.lead.id);
      if (_leadIndex > -1) {
        _leadList[_leadIndex].wa_status = 'read';
      }
      window.localStorage.setItem('leadList', JSON.stringify(_leadList));
      return {
        ...state,
        agentHasUnreadMsg: action.payload.agentHasUnreadMsg,
        leadList: _leadList,
      }
    case actionTypes.SET_DELIVERY_STATUS:
      const leadList_ = parseJSON(window.localStorage.getItem('leadList'), []);
      const expandedLeadList_ = [...state.expandedLeadChatList];

      const clonedLeadIndex = leadList_.findIndex(lead => lead.id === action.payload.lead_id);
      const clonedLeadExpandedIndex = expandedLeadList_.findIndex(lead => lead.id === action.payload.lead_id);

      const chatIndex = leadList_[clonedLeadIndex].chatList.findIndex(chatItem => chatItem.id === action.payload.msg_id);

      if (!action.payload.isWidget && leadList_[clonedLeadIndex].chatList[chatIndex]) {
        leadList_[clonedLeadIndex].chatList[chatIndex].status = action.payload.msg_status;
      }
      if (action.payload.isWidget && clonedLeadExpandedIndex > -1) {
        expandedLeadList_[clonedLeadExpandedIndex].chatList[chatIndex].status = action.payload.msg_status;
      }
      window.localStorage.setItem('leadList', JSON.stringify(leadList_));
      return {
        ...state,
        leadList: leadList_,
        expandedLeadChatList: expandedLeadList_,
      }
    case actionTypes.SET_SELECTED_LEAD:
      return {
        ...state,
        selectedLead: action.payload,
      }
    case actionTypes.CLEAR_SELECTED_LEAD:
      return {
        ...state,
        selectedLead: {},
      }
    case actionTypes.SENDING_PROPOSAL:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SENT_PROPOSAL:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.LEAD_DIALING:
      const __leadList = [...state.leadList];
      const __leadIndex = __leadList.findIndex(lead => lead.phoneNumber === action.payload.phoneNumber);
      if (__leadIndex > -1) {
        __leadList[__leadIndex].isDialling = action.payload.dialling;
      }
      return {
        ...state,
        leadList: __leadList,
      }
    default:
      return state;
  }
}

export const agentVerificationReducer = (state, action) => {
  switch (action.type) {
    case 'GET_ITEM':
      const item = state[action.payload.item];
      return {
        item,
      };
    case 'SET_ITEM':
      const mapping = {};
      mapping[action.payload.item] = action.payload.value;
      return {
        ...state,
        ...mapping,
      };
    default:
      return state;
  }
}