import { createActions } from '../../utils';

const resources = [
  'inquiry payment',
  'car tax histories',
  'calculate price',
];

const otherActions = [
  'error loading inquiry payment',
  'reset inquiry payment',
  'reset inquiry payment error message',

  'create digital order',
  'creating digital order',
  'created digital order',
  'error creating digital order',
  'reset digital order error message',

  'error loading car tax histories',
  'set mobil tax form values',
  'reset price'
];

export default createActions(resources, otherActions);
