/**
 * Safe parse util to avoid unneccessary runtime errors
 * Exports the parsed value if anything goes wrong fallback or null
 */
export const parseJSON = (parseString, fallback) => {
  try {
    return JSON.parse(parseString);
  } catch (e) {
    return fallback || null;
  }
};
