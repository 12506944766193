import { createActions } from '../../utils';

const resources = [
  'agent monthly data',
  'agent quotation plan and order',
  'agent level',
  'person',
  'personal account',
  'lead info',
  'lead quotation info',
  'lead list',
  'bank account detail',
  'balance',
  'lead summary list',
  'transaction summary',
  'transaction summary',
  'agent performance',
  'withdrawal summary list',
  'agent data',
  'agent teams',
  'user details',
  'caller info',
  'agent leaderboard',
  'call recording list',
  'call recording by number',
  'agent detail',
  'call logs by agent',
  'call logs by lead'
];

const otherActions = [
  'update table status',

  'update schedule',
  'updating schedule',
  'updated schedule',
  'error updating schedule',

  'update quotations',
  'updating quotations',
  'updated quotations',
  'error updating quotations',
  'set quotations status',
  'reset quotations status',

  'update queue schedule',
  'updating queue schedule',
  'updated queue schedule',
  'error updating queue schedule',
  'error loading agent quotation plan and order',

  'update quotation',
  'updating quotation',
  'updated quotation',
  'error updating quotation',

  'update person',
  'updating person',
  'updated person',
  'error updating person',
  'set update person status',
  'reset update person status',
  'reset update person error message',

  'update personal account',
  'updating personal account',
  'updated personal account',
  'error updating personal account',
  'error loading personal account',

  'update policy member',
  'updating policy member',
  'updated policy member',
  'error updating policy member',
  'set update policy member status',
  'reset update policy member status',

  'update declaration',
  'updating declaration',
  'updated declaration',
  'error updating declaration',
  'set update declaration status',
  'reset update declaration status',

  'update quotation status',
  'updating quotation status',
  'updated quotation status',
  'error updating quotation status',

  'update refund order',
  'updating refund order',
  'updated refund order',
  'error updating refund order',

  'error loading quotation detail',
  'error loading agent monthly data',
  'error loading person',
  'error loading agent level',

  'agent loading quotation plan and order',
  'error loading agent monthly data',

  'agent dashboard search',
  'searching agent dashboard',
  'agent dashboard search result',

  'create lead',
  'creating lead',
  'created lead',
  'error creating lead',
  'clear lead',

  'reject lead',
  'rejecting lead',
  'rejected lead',
  'error rejecting lead',

  'error loading lead info',
  'error loading lead quotation info',
  'error loading bank account detail',
  'error loading balance',
  'error loading transaction summary',
  'error loading agent performance',
  'error loading leaderboard',

  'process withdrawal',
  'processing withdrawal',
  'processed withdrawal',
  'error processing withdrawal',

  'authenticate withdrawal',
  'authenticating withdrawal',
  'authenticated withdrawal',
  'error authenticating withdrawal',
  'reset authenticated withdrawal',

  'error loading agent data',

  'create queue entry',
  'creating queue entry',
  'created queue entry',
  'error creating queue entry',

  'mark agent availability',
  'marking agent availability',
  'marked agent availability',
  'error marking agent availability',

  'update agent availability',

  'update autodial availability',

  'initiate manual dial',
  'initiating manual dial',
  'initiated manual dial',
  'error initiating manual dial',

  'reset agent data',

  'reset caller info',

  'create notes',
  'creating notes',
  'created notes',
  'error creating notes',

  'get notes history',
  'loading notes history',
  'loaded notes history',
  'error loading notes history',

  'initiate auto dial',
  'initiating auto dial',
  'initiated auto dial',
  'error initiating auto dial',

  'set manual dial',

  'get agent status',
  'loading agent status',
  'loaded agent status',
  'error loading agent status',

  'update agent stats',

  'get lead list by agentID',
  'loading lead list by agentID',
  'loaded lead list by agentID',

  'error loading agent data',
  'error loading agent teams',
  'error loading call logs by agent',
  'error loading call logs by lead',
  'reset call logs',

  'set agent status',

  'expand lead chat',
  'minimize lead chat',

  'get lead by leadID',
  'loading lead by leadID',
  'loaded lead by leadID',

  'send message',
  'sending message',
  'sent message',
  
  'get message list',
  'loading message list',
  'loaded message list',

  'send template',
  'sending template',
  'sent template',

  'add new message',

  'get agent chat history',
  'loading agent chat history',
  'loaded agent chat history',

  'set agent unread status',

  'set delivery status',

  'set selected lead',
  'clear selected lead',

  'send proposal',
  'sending proposal',
  'sent proposal',

  'lead dialing',
];

export default createActions(resources, otherActions);
