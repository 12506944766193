import commonActions from '../common/actions';
import accountActions from '../modules/account/actions';
import agentActions from '../modules/agents/actions';
import planActions from '../modules/plans/actions';
import quotationActions from '../modules/quotations/actions';
import orderActions from '../modules/orders/actions';
import digitalProductActions from '../modules/digital-product/action';
import notificationActions from '../modules/notification/action';

const actionTypes = Object.assign({},
  commonActions.actionTypes,
  accountActions.actionTypes,
  agentActions.actionTypes,
  planActions.actionTypes,
  quotationActions.actionTypes,
  orderActions.actionTypes,
  digitalProductActions.actionTypes,
  notificationActions.actionTypes,
);

export { actionTypes };

export default Object.assign({},
  commonActions.actions,
  accountActions.actions,
  agentActions.actions,
  planActions.actions,
  quotationActions.actions,
  orderActions.actions,
  digitalProductActions.actions,
  notificationActions.actions,
);
